.data-privacy-dialog-container {
  padding-right: 20px;
}

.data-privacy-list-container {
  padding-left: 20px;
}

.data-privacy-icon-default svg path {
  height: 20px;
  width: 20px;
}

.data-privacy-icon-dark svg path {
  height: 20px;
  width: 20px;
  fill: #d6d6d6;
}

.data-privacy-icon-contrast svg path {
  height: 20px;
  width: 20px;
  fill: rgb(255, 255, 255);
}

.data-privacy-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, 280px);
  gap: 20px;
}

.data-privacy-list-initial {
  margin-top: 15px;
  padding-left: 20px;
  padding-bottom: 20px;
}

.data-privacy-consent-section {
  padding-left: 5px;
  padding-right: 5px;
  margin-top: -12px;
  margin-left: -12px;
  margin-right: -12px;
}

.data-privacy-action {
  padding-right: 18px;
  padding-top: 10px;
  text-align: right;
}

.data-privacy-action Button {
  margin-right: 10px; 
}

.data-privacy-initial-action {
  padding-top: 10px;
  margin-bottom: -25px;
  text-align: right;
}

.data-privacy-initial-action Button {
  margin-left: 10px; 
}

.data-privacy-update-spinner {
  padding-top: 10px;
}