.text-center {
    text-align: center
}

.flex {
    display: flex
}

.image-width {
    width: 30%
}

.popover {
    z-index: 1;
}

.label-width{
    max-width: 120px;
    min-width: 120px;
    font-weight: 500;
    padding-right: 20px;
}

.edit-label-width{
    min-width: 120px;
    font-weight: 500;
}

.open-in-button {
    padding-inline-start: 3px !important;
    padding-inline-end: 0px !important;
    text-wrap: nowrap;
}