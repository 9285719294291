.error-buttons-container {
    margin-bottom: 10px;
    margin-top: 10px;
    display: inline;
    margin-right: 5px;
}

.button-margin-right {
    margin-right: 5px !important;
}

.spinner-margin-right {
    margin-right: 5px;
}