.command-bar-container {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    padding-left: 10px;
} 

.search-bar {
    width: 27vw;
    max-width: 183px;
}