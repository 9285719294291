.workflow-task-image {
    height: 220px;
    width: 280px;
    text-align: center;
}

.workflow-task-container {
    height: 360px;
}

.workflow-task-header {
    margin-top: -12px;
    margin-left: -12px;
    margin-right: -12px;
}

.workflow-card-image-container {
    display: flex;
    justify-content: center;
    height: 220px;
    max-height: 220px;
}

.workflow-card {
    max-width: 276px;
}

.workflow-task-synopsis {
    max-width: 276px;
    text-overflow: ellipsis;
    display: block;
}

.workflow-name-container {
    display: flex;
    margin-top: 1px;
}

.workflow-calendar-icon {
    margin-top: 1px;
}

.workflow-name-value {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 2px;
}

.workflow-target-value {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 2;
    max-width: 276px;
    text-overflow: ellipsis;
    display: block;
  }

.workflow-name-icon {
    margin-top: 1;
}

.workflow-calendar-container {
    display: flex;
    margin-top: 1px;
}

.workflow-calendar-value {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 2px;
}

.card-footer {
    margin-right: 0;
    margin-left: Auto;
}

.workflow-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, 286px);
    row-gap: 50px;
    column-gap: 10px;
    padding-top: 155px;
    justify-content: center;
    margin: auto;
    overflow-y: scroll;
    height:calc(100vh - 50px);
}

.workflow-task-list {
    position: relative;
}

.workflow-task-toolbar {
    position: fixed;
    width: 100%;
    z-index: 1;
}

.workflow-list-banner {
    padding-top: 50px;
}

.workflow-task-dialog-action {
    display: flex;
    gap: 10px;
    position: absolute;
    right: 25px;
    bottom: 20px;
}

.label-width{
    max-width: 100px;
    min-width: 100px;
    font-weight: 600;
}

.workflow-task-formBody {
    padding-left: 30px;
    padding-right: 25px;
    margin-bottom: 0px;
    overflow: hidden;
    height: 100vh;
    width: 100vw;
}

.workflow-task-formContainer {
    display: flex;
    overflow: auto;
    flex-direction: column;
    row-gap: 10px;
}

.no-result-container {
    margin-top: 100;
}

.toolbar {
    justify-content: space-between;
}

.sort-popover {
    z-index: 1
}