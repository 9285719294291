div.header {
  height: 12em;
  background-image: -ms-radial-gradient(75% top, ellipse farthest-side, #55a0b9 -100%, #0077a1 40%, #006b91 75%, #006487 calc(100% - 68px));
  background-image: -moz-radial-gradient(75% top, ellipse farthest-side, #55a0b9 -100%, #0077a1 40%, #006b91 75%, #006487 calc(100% - 68px));
  background-image: -o-radial-gradient(75% top, ellipse farthest-side, #55a0b9 -100%, #0077a1 40%, #006b91 75%, #006487 calc(100% - 68px));
  background-image: -webkit-gradient(
    radial,
    75% top,
    0,
    center top,
    258,
    color-stop(-100, #55a0b9),
    color-stop(40, #0077a1),
    color-stop(75, #006b91),
    color-stop(100, #006487));
  background-image: -webkit-radial-gradient(75% top, ellipse farthest-side, #55a0b9 -100%, #0077a1 40%, #006b91 75%, #006487 calc(100% - 68px));
  background-image: radial-gradient(ellipse farthest-side at 75% top, #55a0b9 -100%, #0077a1 40%, #006b91 75%, #006487 calc(100% - 68px));
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  left: 0;
  right: 0;
}

div.header h1 {
  text-align: center;
  background-image: none;
  background-color: transparent;
  color: white;
}

.banner {
  /* Subtle gradient background from dark blue to a slightly lighter blue */
  background: linear-gradient(to bottom, #000028, #000040);
  overflow: hidden;
  height: 12em;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
}

.column-left {
  width: 60%;
}

.column-right {
  width: 40%;
}

.logo-left,
.logo-right {
  background-size: contain;
  background-repeat: no-repeat;
}

.logo-left {
  position: relative;
  width: auto;
  height: 115%;
  margin-top: max(-40px,-6vh);
  margin-left: max(-40px,-6vh);
}

.logo-right {
  position: relative;
  width: 80%;
  height: 80%;
  top: -20%;
  right: -15%;
  background-position: top right;
}