
.toolbar {
    justify-content: space-between;
    padding-bottom: 10px !important;
}

/* This is required to avoid "+" icon not being centered in the "New Problem Report" button*/
.toolbar-button-mobile span {
    margin-right: 0px;
}

.toolbar-group {
    display: inline-flex;
}

.problem-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, 286px);
    gap: 10px;
    padding-top: 155px;
    justify-content: center;
    margin: auto;
    overflow-y: scroll;
    height:calc(100vh - 50px);
}

.problem-report-list {
    position: relative;
}

.problem-report-toolbar {
    position: fixed;
    width: 100%;
    z-index: 1;
}

.problem-list-banner {
    padding-top: 50px;
}

.problem-card-container {
    height: 360px;
}

.problem-card {
    max-width: 276px;
}

.problem-card-header {
    margin-top: -12px;
    margin-left: -12px;
    margin-right: -12px;
}

.problem-card-footer {
    margin-right: 0;
    margin-left: auto;
}

.problem-card-image-container {
    display: flex;
    justify-content: center;
    height: 220px;
    max-height: 220px;
}

.problem-info-image-container {
    display: flex;
    justify-content: center;
    height: 220px;
}

.problem-item-id {
    position: absolute !important;
    top: 10px;
    right: 10px;
}

.problem-item-closure {
    position: absolute !important;
    top: 10px;
    left: 10px;
}

.problem-item {
    max-width: 276px;
    text-overflow: ellipsis !important;
    display: block !important;
}

.problem-item-date-container {
    display: flex;
    margin-top: 1px;
}

.problem-item-date-value {
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 2px;
}

.problem-item-date-icon {
    margin-top: 1px;
}


.problem-report-image {
    height: 250px;
    width: 250px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.no-result-container {
    margin-top: 100px;
}

.problem-create-formBody {
    padding-left: 30px;
    padding-right: 25px;
    margin-bottom: 0px;
    overflow: hidden;
    height: 100vh;
    width: 100vw;
}

.problem-info-formBody {
    padding-left: 30px;
    padding-right: 25px;
    margin-bottom: 0px;
    overflow: hidden;
    height: 100vh;
    width: 100vw;
}

.problem-create-formContainer {
    display: flex;
    overflow: auto;
    flex-direction: column;
    row-gap: 10px;
}

.problem-info-formContainer {
    display: flex;
    overflow: auto;
    flex-direction: column;
    row-gap: 10px;
}

.problem-create-summaryFormContainer {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column,
}

.problem-create-dialog-action {
    display: flex;
    gap: 10px;
    position: absolute;
    bottom: 20px;
    right: 25px;
}

.problem-info-dialog-action {
    display: flex;
    gap: 10px;
    position: absolute;
    bottom: 20px;
    right: 25px;
}

.create-problem-label-tab {
    padding-left: 10px;
    color: #464646 !important;
}

.problem-create-dialog-images {
    padding-top: 20px;
    padding-left: 30px;
    display: flex !important;
    flex-direction: row !important;
    column-gap: 20px;
}

.problem-card-image-left-panel{
    width:60%;
    float:left;
}

.problem-card-image-right-panel{
    float: right;
}

.problemitem-picker-searchContainer {
    box-sizing: 'border-box';
    background-attachment: 'scroll';
    background-clip: 'border-box';
    background-origin: 'padding-box';
    display: 'block';
    position: 'relative';
    z-index: '1000000';
    width: 'inherit';
    max-height: '20vh';
}

.problemitem-picker-searchResults {
    box-sizing: 'border-box';
    display: 'flex';
    flex-direction: 'column';
    min-width: '160px';
    position: 'absolute';
    border-bottom-left-radius: '4px';
    border-bottom-right-radius: '4px';
    border-top-left-radius: '4px';
    border-top-right-radius: '4px';
    box-shadow: '0 0 2px rgba(0,0,0,0.12), 0 8px 16px rgba(0,0,0,0.14)';
    max-height: '20vh';
    width: 'inherit';
    height: 'inherit';
    min-height: '35px';
}
.problemitem-picker-searchResultItem {
    align-items: 'center';
    cursor: 'pointer';
    display: 'flex';
    line-height: '20px';
    position: 'relative';
    height: '32px';
    padding-top: '6';
    padding-bottom: '6';;
    padding-left: '8';
    padding-right: '8';
    border-bottom-left-radius: '4px';
    border-bottom-right-radius: '4px';
    border-top-left-radius: '4px';
    border-top-right-radius: '4px';
    :hover {
      background-color: '#f5f5f5',
    }
}

.problemitem-picker-menuMessages {
    cursor: 'auto';
    display: 'flex';
    height: '32px';
    width: 'inherit';
    position: 'relative';
    padding-top: '6';
    padding-bottom: '6';
    padding-left: '8';
    padding-right: '8';
    border-bottom-left-radius: '4px';
    border-bottom-right-radius: '4px';
    border-top-left-radius: '4px';
    border-top-right-radius: '4px';
    :hover {
        background-color: 'inherit'
    }
}

.problem-header-wrapper {
    grid-column: span 3;
    margin-top: 5px;
}

.summarize-button {
    width: 100%
}

.create-button-group {
    display: flex;
    gap: 10px;
    position: absolute;
    bottom: 20px;
    right: 25px;
}

.create-button {
    width: 50%
}

.feedback-button-group {
    flex-direction: row;
    column-gap: 10px;
    padding-bottom: 10px;
}

.selected-feedback-button {
    background-color: #f5f5f5;
    border-color: #c7c7c7;
    color: #242424
}

.tell-me-human-oversight-actions {
    margin-top: 20px;
}

.attachment-div{
    display: flex;
    justify-content: space-between;
}

.problem-types-input {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 10px;
}

.menulist-width{
    width: 135px;
}

.participant-section{
    display: flex;
    flex-direction: column;
    border: ridge;
}

.participant-section-label{
    align-self: flex-start;
    margin-left: 10px;
    margin-top: 5px;
}

.participant-section-header{
    display: flex;
    justify-content: space-between;
}

.participant-section-button{
    align-self: end;
}

.participant-section-button > button {
    margin-right: 2px; /* Adds space to the right of each button */
    margin-top: 2px; /* Adds space to the top of each button */
}

.participant-section-list{
    display: flex;
    margin-left: 5px;
    align-items: center;
    padding-bottom: 5px;
}

.selected-participant{
    background-color: lightgray;
}

.not-selected-participant{
    background-color: transparent;
}

.add-participant-container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
    height: 80vh;
}

.search-area {
    flex: 1;
}

.user-list-area {
    overflow-y: auto;
    height: 70vh;
    scrollbar-width: thin;
    padding-top: 3px;
    padding-bottom: 3px;
}

.selected-user-area {
    overflow-y: auto;
    height: 74vh;
    scrollbar-width: thin;
}

.selected-area {
    flex: 1;
    margin-left: 5px;
}

.splitter {
    width: 1px;
    background-color: #ccc;
}

.user-container {
    display: flex;
    position: relative;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%; 
}

.avatar-container {
    margin-right: 3px; /* Space between avatar and user details */
    margin-left: 3px;
}

.user-details-container {
    display: flex;
    flex-direction: column;
    padding-right: 23px; /* Space between user details and action buttons */
}

.participant-action-buttons {
    display: flex;
    position: absolute;
    bottom: 10px;
    right: 20px;
    gap: 10px;
    flex-direction: row;
}

.add-user-button{
    position: absolute;
    top: 0;
    right: 0;
}

.add-regular-icon{
    font-size: 14;
}

.people-add-regular-icon{
    font-size: 24;
}