.date-time-container {
  display: flex;
}

.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
  min-width: 50%;
}

.time-picker-container {
  margin-left: 5px;
}

span.ms-Button-flexContainer {
  display: contents;
}