.popup-style {
    width: 100%;
    text-align: center
}

.login-popup {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
}

.login-button {
    margin-right: 5px
}